import type {ConnectElementImportKeys} from '../ConnectJSInterface/ConnectElementList';
import {CONNECT_ELEMENT_IMPORTS} from '../ConnectJSInterface/ConnectElementList';
import {getStronglyTypedKeys} from '../../utils/getStronglyTypedKeys';

export const connectElementTags = getStronglyTypedKeys(CONNECT_ELEMENT_IMPORTS);

export const isConnectElementTag = (
  name: string,
): name is ConnectElementImportKeys => {
  return (
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    !!name && connectElementTags.includes(name as ConnectElementImportKeys)
  );
};

// All the vanilla JS names are the same as the HTML names, but without the `stripe-connect` part
export const componentNameMapping: Record<string, ConnectElementImportKeys> =
  connectElementTags.reduce((prevVal, newKey) => {
    const vanillaHtmlName = newKey.replace('stripe-connect-', '');
    return {
      ...prevVal,
      [vanillaHtmlName]: newKey,
    };
  }, {} as Record<string, ConnectElementImportKeys>);
